<template>
  <with-side-navigations side="left">
    <template #sideNavigation>
      <contractors-sidebar />
    </template>
    <div>
      <div class="flex flex-wrap items-center justify-between">
        <div class="flex items-center">
          <h1 class="text-3xl">{{ $t('contractors-view.contractors') }}</h1>
          <pagination-select :query="routeQuery" @select-limit="selectLimit" />
        </div>
        <div class="flex">
          <filter-toggler class="mr-4" />
          <router-link :to="{ name: 'AddContractor' }">
            <btn class="w-26">{{ $t('common.add') }}</btn>
          </router-link>
        </div>
      </div>
      <tabs class="mt-8">
        <tab :name="$t('contractors-view.all')" selected>
          <table-list
            content="organizations"
            :query="query"
            route-component="Contractors"
            class="pb-16 mt-1 hover-click"
            :cols="columns"
            with-thumbs
            :mobile-cols="1"
            :force-update="listUpdatesCounter"
            thumb-key="cdnLogoUrl"
            :thumb-cdn="IMAGE_THUMB_SIZE"
            contain-img
            :actions="{
              edit: onEditBtnClick,
              delete: {
                handler: onDeleteBtnClick,
                modal: {
                  type: 'confirm',
                  title: $t('contractors-view.modal-title'),
                  description: $t('contractors-view.modal-description'),
                  btnText: $t('contractors-view.modal-btn'),
                },
              },
            }"
            checkbox
            :selected-ids="selectedContractors"
            @record-click="
              $router.push({
                name: 'ContractorDetails',
                params: { contractorId: $event },
              })
            "
            @select="selectedContractors = $event"
          ></table-list>
        </tab>
      </tabs>
    </div>
  </with-side-navigations>
</template>

<script>
import Btn from '@/components/Button/Button.vue';
import FilterToggler from '@/components/Filters/FilterToggler.vue';
import WithSideNavigations from '@/layouts/WithSideNavigations.vue';
import ContractorsSidebar from '@/views/Dashboard/Contractors/ContractorsSidebar.vue';
import TableList from '@/components/Table/TableList.vue';
import Tab from '@/components/Tabs/Tab.vue';
import Tabs from '@/components/Tabs/Tabs.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { fontNormal } from '@/util/formatters';
import { PaginationSelect } from '@/components/Inputs';
import { IMAGE_THUMB_SIZE } from '@/util/images';
import routeQuery from '@/mixins/query/routeQuery';
import { OrganizationType } from '@/constants/organization.ts';

export default {
  components: {
    Btn,
    TableList,
    Tab,
    Tabs,
    WithSideNavigations,
    ContractorsSidebar,
    PaginationSelect,
    FilterToggler,
  },
  data() {
    return {
      selectedContractors: [],
      columns: {
        name: { label: this.$t('contractors-view.name') },
        address: {
          label: this.$t('contractors-view.address'),
          merge: {
            props: ['address', 'postalCode', 'city'],
            format: '$0, $1 $2',
          },
          formatter: fontNormal,
        },
      },
      listUpdatesCounter: 0,
      IMAGE_THUMB_SIZE,
      query: { type: OrganizationType.CONTRACTOR },
    };
  },
  mixins: [routeQuery],
  computed: {
    ...mapState('client', {
      clientDetails: (state) => state.details,
    }),
    ...mapGetters('routes', ['filterQuery']),
    clientId() {
      return this.clientDetails.id;
    },
  },
  methods: {
    ...mapActions('common', ['delete']),
    onEditBtnClick(id) {
      this.$router.push({
        name: 'EditContractor',
        params: { contractorId: id },
      });
    },
    async onDeleteBtnClick(id) {
      await this.delete({ type: 'contractors', id });

      this.listUpdatesCounter++;
      this.$toast.success(this.$t('contractors-view.deleted-successfully'));
    },
  },
};
</script>

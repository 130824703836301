var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('with-side-navigations',{attrs:{"side":"left"},scopedSlots:_vm._u([{key:"sideNavigation",fn:function(){return [_c('contractors-sidebar')]},proxy:true}])},[_c('div',[_c('div',{staticClass:"flex flex-wrap items-center justify-between"},[_c('div',{staticClass:"flex items-center"},[_c('h1',{staticClass:"text-3xl"},[_vm._v(_vm._s(_vm.$t('contractors-view.contractors')))]),_c('pagination-select',{attrs:{"query":_vm.routeQuery},on:{"select-limit":_vm.selectLimit}})],1),_c('div',{staticClass:"flex"},[_c('filter-toggler',{staticClass:"mr-4"}),_c('router-link',{attrs:{"to":{ name: 'AddContractor' }}},[_c('btn',{staticClass:"w-26"},[_vm._v(_vm._s(_vm.$t('common.add')))])],1)],1)]),_c('tabs',{staticClass:"mt-8"},[_c('tab',{attrs:{"name":_vm.$t('contractors-view.all'),"selected":""}},[_c('table-list',{staticClass:"pb-16 mt-1 hover-click",attrs:{"content":"organizations","query":_vm.query,"route-component":"Contractors","cols":_vm.columns,"with-thumbs":"","mobile-cols":1,"force-update":_vm.listUpdatesCounter,"thumb-key":"cdnLogoUrl","thumb-cdn":_vm.IMAGE_THUMB_SIZE,"contain-img":"","actions":{
            edit: _vm.onEditBtnClick,
            delete: {
              handler: _vm.onDeleteBtnClick,
              modal: {
                type: 'confirm',
                title: _vm.$t('contractors-view.modal-title'),
                description: _vm.$t('contractors-view.modal-description'),
                btnText: _vm.$t('contractors-view.modal-btn'),
              },
            },
          },"checkbox":"","selected-ids":_vm.selectedContractors},on:{"record-click":function($event){return _vm.$router.push({
              name: 'ContractorDetails',
              params: { contractorId: $event },
            })},"select":function($event){_vm.selectedContractors = $event}}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
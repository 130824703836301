<template>
  <div>
    <filter-group v-slot="{ resetFilters, filtersApplied }" v-model="filters">
      <div class="flex items-center justify-between">
        <h1 class="text-lg font-semibold">{{ $t('common.filter') }}</h1>
        <btn
          v-if="filtersApplied"
          theme="secondary"
          class="w-26"
          @click="resetFilters"
        >
          {{ $t('common.reset') }}
        </btn>
      </div>
      <filter-wrapper
        :parameter="FILTER_PARAMS.SEARCH"
        :value="filters[FILTER_PARAMS.SEARCH]"
      >
        <text-input
          v-model="filters[FILTER_PARAMS.SEARCH]"
          :placeholder="$t('common.search')"
          :prepend-icon="mdiMagnify"
          debounce
          clearable
          class="mb-5 mt-6"
        />
      </filter-wrapper>
    </filter-group>
  </div>
</template>

<script>
import FilterGroup from '@/components/Filters/FilterGroup.vue';
import FilterWrapper from '@/components/Filters/FilterWrapper.vue';
import Btn from '@/components/Button/Button';
import { TextInput } from '@/components/Inputs';
import { FILTER_PARAMS } from '@/constants/filters';
import { mdiMagnify } from '@mdi/js';

export default {
  name: 'PlacesSidebar',
  components: {
    FilterGroup,
    FilterWrapper,
    Btn,
    TextInput,
  },
  data() {
    return {
      mdiMagnify,
      FILTER_PARAMS,
      filters: {
        [FILTER_PARAMS.SEARCH]: '',
        [FILTER_PARAMS.SORT_BY]: null,
      },
    };
  },
};
</script>
